
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { useRouter, useRoute } from "vue-router";
import { preventDefault } from "@fullcalendar/common";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "appointment-bus-info",
  components: {
    Form,
    Field,
    ErrorMessage,
    ElCurrencyInput,
  },
  props: ["businessesInfo", "serviceId"],
  setup(props, { emit }) {
    const formBusValidator = Yup.object({
      business: Yup.object({
        name: Yup.string().required().label("Business Name	"),
        reg_number: Yup.string().required().label("Registration Number"),
        type: Yup.string().required().label("Business Type"),
        email: Yup.string().required().label("Email"),
        in_operation: Yup.string().required().label("Please Select Yes or No"),
      }),
    });

    const formBankValidator = Yup.object({
      bank: Yup.object({
        name: Yup.string()
          .required("please select your bank")
          .label("Bank's Name"),
        account_type: Yup.string()
          .required("please select Bank Account Type")
          .label("Account Type"),
        account_no: Yup.number()
          .typeError("Please enter numbers only")
          .required("Please enter account number")
          .label("Account Number"),
      }),
    });

    const sectors = [
      { value: "Agriculture", label: "Agriculture" },
      { value: "Construction", label: "Construction" },
      { value: "Electricity, Gas & Water", label: "Electricity, Gas & Water" },
      { value: "Manufacturing", label: "Manufacturing" },
      { value: "Mining & Quarry", label: "Mining & Quarry" },
      { value: "Real Estate", label: "Real Estate" },
      { value: "Wholesale", label: "Wholesale" },
      { value: "Communication", label: "Communication" },
      { value: "Transport & Storage", label: "Transport & Storage" },
      { value: "Restaurant & Hotel", label: "Restaurant & Hotel" },
      { value: "Other Services", label: "Other Services" },
    ];

    const in_operations = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
    ];

    const premise_statuses = [
      { value: "Owned", label: "Owned" },
      { value: "Rental", label: "Rental" },
    ];

    const bus_types = [
      { value: "Sole Proprietor", label: "Sole Proprietor" },
      { value: "Partnership", label: "Partnership" },
      {
        value: "Private Limited Company",
        label: "Private Limited Company (Sdn Bhd)",
      },
      {
        value: "Limited Liability Partnership",
        label: "Limited Liability Partnership",
      },
    ];

    const busconditions = [
      {
        value: "woundup",
        label:
          "Wound up i.e. Winding up court order obtained, and liquidator appointed",
      },
      {
        value: "bankrupt",
        label:
          "Any one of the owners/directors/shareholders who is adjudicated a bankrupt",
      },
      { value: "receiver", label: "Receivers & Manager appointed" },
      { value: "judicial", label: "Judicial manager appointed" },
      { value: "none", label: "None of the above" },
    ];

    const accountTypes = [
      {
        value: "Saving",
        label: "Saving",
      },
      {
        value: "Current",
        label: "Current",
      },
    ];

    const store = useStore();
    const banks = ref([]);
    store.dispatch(Actions.GET_CREDITORS).then(() => {
      banks.value = store.getters.getCreditorsData;
    });

    return {
      formBusValidator,
      formBankValidator,
      sectors,
      in_operations,
      premise_statuses,
      bus_types,
      busconditions,
      accountTypes,
      banks,
    };
  },
  data() {
    const business = {
      name: "",
      reg_number: "",
      reg_date: "",
      type: this.service ? this.service : "",
      eco_sector: "",
      no_of_employee: 0,
      annual_income: 0,
      in_operation: "",
      address: "",
      premise_status: "",
      bus_condition: "",
      banks: [],
    };

    const businesses = this.businessesInfo;

    const bank = {
      name: "",
      account_type: "",
      account_no: "",
    };

    return {
      store:useStore(),
      route:useRoute(),
      business,
      businesses,
      states: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Kuala Lumpur",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sabah",
        "Selangor",
        "Sarawak",
        "Terengganu",
        "Wilayah Persekutuan Kuala Lumpur",
        "Wilayah Persekutuan Labuan",
        "Wilayah Persekutuan Putrajaya",
      ],
      bank,
    };
  },
  methods: {
    onAddBusInfo(value, { resetForm }) {
      this.businesses.push(this.business);
      resetForm();
      this.business = {
        name: "",
        reg_number: "",
        reg_date: "",
        type: "",
        eco_sector: "",
        no_of_employee: 0,
        annual_income: 0,
        in_operation: "",
        address: "",
        premise_status: "",
        bus_condition: "",
        banks: [],
      };
      this.saveBusinesses();
    },
    onAddBankInfo(value, { resetForm }) {
      this.business.banks.push(this.bank);
      resetForm();
      this.bank = {
        name: "",
        account_type: "",
        account_no: "",
      };
    },
    onchangeBusinessType() {
      this.$emit("showEmploymentSection", this.business.type);
    },

    onShowBusinessTypeDetails() {
      const modalBusinessType = new Modal(
        document.getElementById("modalBusinessType")
      );
      modalBusinessType.show();
    },
    onInvalidAddBusInfo(){
      const form = this.$refs.businessForm as any;
      form?.$el.scrollIntoView({ behavior: "smooth" });
    },
    saveBusinesses(){
      this.store.dispatch(Actions.STORE_RAW_APPOINTMENT,{
        user_id: this.route.params.id,
        type:'businesses',
        raw: this.businesses
      })
      .then( () => {
        Swal.fire({
          title: 'Saved',
          text: "Customer Profile Information has been saved.",
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then( () => {
          this.$emit('initParent');
        })
      })
    }
  },
});
