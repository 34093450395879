
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "appointment-bus-info",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: ["employmentInfo"],
  setup() {
    const educationLevels = [
      { value: "Others", label: "Others" },
      { value: "Primary", label: "Primary" },
      { value: "Secondary", label: "Secondary" },
      {
        value: "Tertiary (College or University)",
        label: "Tertiary (College or University)",
      },
    ];

    const employmentTypes = [
      { value: "Employed", label: "Employed" },
      { value: "Retiree", label: "Retiree" },
      { value: "Self-Employed", label: "Self-Employed" },
      { value: "Unemployed", label: "Unemployed" },
    ];

    const employerTypes = [
      { value: "Government", label: "Government" },
      { value: "Own Business", label: "Own Business" },
      { value: "Private", label: "Private" },
      { value: "Professional", label: "Professional" },
      { value: "Retiree With Pension", label: "Retiree With Pension" },
      {
        value: "Unemployed/Retiree With No Pension",
        label: "Unemployed/Retiree With No Pension",
      },
    ];

    const sectors = [
      { value: "Agriculture", label: "Agriculture" },
      { value: "Construction", label: "Construction" },
      { value: "Electricity, Gas & Water", label: "Electricity, Gas & Water" },
      { value: "Manufacturing", label: "Manufacturing" },
      { value: "Mining & Quarry", label: "Mining & Quarry" },
      { value: "Real Estate", label: "Real Estate" },
      { value: "Services", label: "Services" },
      { value: "Wholesale", label: "Wholesale" },
    ];

    const specialisations = [
      {
        value: "Acturial Science/Statistics",
        label: "Acturial Science/Statistics",
      },
      {
        value: "Advertising/Media Planning",
        label: "Advertising/Media Planning",
      },
      {
        value: "Agriculture/Plantation/Fisheries",
        label: "Agriculture/Plantation/Fisheries",
      },
      {
        value: "Architecture/Interior Design",
        label: "Architecture/Interior Design",
      },
      {
        value: "Arts/Creative/Graphic Design",
        label: "Arts/Creative/Graphic Design",
      },
      {
        value: "Aviation/Aircraft Maintenance",
        label: "Aviation/Aircraft Maintenance",
      },
      {
        value: "Banking/Financial Services",
        label: "Banking/Financial Services",
      },
      { value: "Civil Servant", label: "Civil Servant" },
      {
        value:
          "Clerical/Administrative Support/Secretarial/Personal Assistance",
        label:
          "Clerical/Administrative Support/Secretarial/Personal Assistance",
      },
      { value: "Customer Service", label: "Customer Service" },
      { value: "Digital Marketing", label: "Digital Marketing" },
      { value: "Education", label: "Education" },
      { value: "Engineering", label: "Engineering" },
      {
        value: "Entertainment/Performing Arts",
        label: "Entertainment/Performing Arts",
      },
      { value: "Executive", label: "Executive" },
      { value: "Finance - Audit/Taxation", label: "Finance - Audit/Taxation" },
      {
        value: "Finance - Corporate Finance/Investment/Merchant Banking",
        label: "Finance - Corporate Finance/Investment/Merchant Banking",
      },
      {
        value: "Finance - General/Cost Accounting",
        label: "Finance - General/Cost Accounting",
      },
      {
        value: "Food Technology/Nutritionist",
        label: "Food Technology/Nutritionist",
      },
      {
        value: "Food/Beverage/Restaurant Service",
        label: "Food/Beverage/Restaurant Service",
      },
      {
        value: "General Worker (housekeeper, driver, dispatch, cleaner etc.)",
        label: "General Worker (housekeeper, driver, dispatch, cleaner etc.)",
      },
      {
        value: "Healthcare - Doctor/Specialist/Diagnostician",
        label: "Healthcare - Doctor/Specialist/Diagnostician",
      },
      {
        value: "Healthcare - Nurse/Medical Support &amp; Assistant",
        label: "Healthcare - Nurse/Medical Support &amp; Assistant",
      },
      { value: "Healthcare - Pharmacy", label: "Healthcare - Pharmacy" },
      {
        value: "Hotel Management/Tourism Service",
        label: "Hotel Management/Tourism Service",
      },
      { value: "Human Resource", label: "Human Resource" },
      { value: "IT/Computer - Hardware", label: "IT/Computer - Hardware" },
      {
        value: "IT/Computer - Network/System/Database Admin",
        label: "IT/Computer - Network/System/Database Admin",
      },
      { value: "IT/Computer - Software", label: "IT/Computer - Software" },
      {
        value: "IT/Computer - Technical &amp; Helpdesk Support",
        label: "IT/Computer - Technical &amp; Helpdesk Support",
      },
      { value: "Journalist/Editor", label: "Journalist/Editor" },
      { value: "Law/Legal Services", label: "Law/Legal Services" },
      { value: "Logistics/Supply Chain", label: "Logistics/Supply Chain" },
      { value: "Management", label: "Management" },
      {
        value: "Marketing/Business Development",
        label: "Marketing/Business Development",
      },
      {
        value: "Mechanics/Technician/Welder",
        label: "Mechanics/Technician/Welder",
      },
      { value: "Merchandising", label: "Merchandising" },
      { value: "Others", label: "Others" },
      {
        value: "Personal Care/Beauty/Fitness Service",
        label: "Personal Care/Beauty/Fitness Service",
      },
      { value: "Property/Real Estate", label: "Property/Real Estate" },
      {
        value: "Public Relations/Communications",
        label: "Public Relations/Communications",
      },
      { value: "Retiree", label: "Retiree" },
      { value: "Sales", label: "Sales" },
      {
        value: "Science &amp; Technology/Laboratory",
        label: "Science &amp; Technology/Laboratory",
      },
      {
        value: "Security/Protective Services",
        label: "Security/Protective Services",
      },
      {
        value: "Social &amp; Counselling Service",
        label: "Social &amp; Counselling Service",
      },
      { value: "Top Management", label: "Top Management" },
      {
        value: "Training &amp; Development",
        label: "Training &amp; Development",
      },
    ];

    const incomeRanges = [
      {
        value: "<=1000",
        label: "<=1000",
      },
      {
        value: "1001 -  2000",
        label: "1001 -  2000",
      },
      {
        value: "2001 - 3000",
        label: "2001 - 3000",
      },
      {
        value: "3001 - 4000",
        label: "3001 - 4000",
      },
      {
        value: "4001 - 5000",
        label: "4001 - 5000",
      },
      {
        value: "5001 - 10000",
        label: "5001 - 10000",
      },
      {
        value: ">10000",
        label: ">10000",
      },
    ];

    const employmentDurations = [
      {
        value: "Less Than 1 Year",
        label: "Less Than 1 Year",
      },
      {
        value: "1-3 Years",
        label: "1-3 Years",
      },
      {
        value: "3-5 Years",
        label: "3-5 Years",
      },
      {
        value: "5-10 Years",
        label: "5-10 Years",
      },
      {
        value: "More Than 10 Years",
        label: "More Than 10 Years",
      },
      {
        value: "Retiree / Unemployed",
        label: "Retiree / Unemployed",
      },
    ];

    const unEmploymentDurations = [
      {
        value: "Less Than 1 Year",
        label: "Less Than 1 Year",
      },
      {
        value: "Less than 5 Years",
        label: "Less than 5 Years",
      },
      {
        value: "More Than 5 Years",
        label: "More Than 5 Years",
      },
      {
        value: "Recent",
        label: "Recent",
      },
    ];

    return {
      educationLevels,
      employmentTypes,
      employerTypes,
      sectors,
      specialisations,
      incomeRanges,
      employmentDurations,
      unEmploymentDurations,
    };
  },
  data() {
    const employment = this.employmentInfo;

    return {
      store:useStore(),
      route:useRoute(),
      employment,
    };
  },
  methods: {
    init() {
      //
    },
    onSubmit(){
      this.store.dispatch(Actions.STORE_RAW_APPOINTMENT,{
          user_id: this.route.params.id,
          type:'employment',
          raw: this.employment
        })
        .then( () => {
          Swal.fire({
            title: 'Saved',
            text: "Customer Employment Information has been saved.",
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
            allowOutsideClick: false
          }).then( () => {

            this.$emit('initParent');
          })
        })
    }
  },
  async mounted() {
    this.init();
  },
});
