
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

import PersonalInformation from "./PersonalInformationNew.vue";
import EmploymentInfo from "./EmploymentInfo.vue";
import BusinessInfo from "./BusinessInfo.vue";

export default defineComponent({
  name: "customer-details",
  components: {
    PersonalInformation,
    EmploymentInfo,
    BusinessInfo,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Profile", ["Customer"]);
    });

    return {};
  },
  data() {
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const profile = {} as any;
    const employment = {} as any;
    const businesses = {} as any;
    const reverseMortgage = {} as any;
    const myKnp = {} as any;

    const banks = [];
    const isProfileReady = false;

    return {
      store: useStore(),
      cstmr_id,
      business_info: [],
      profile,
      banks,
      isProfileReady,
      employment,
      businesses,
      reverseMortgage,
      myKnp,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
        this.profile = this.store.getters.getCustomerData as any;

        let userRawProfile = this.profile.user_raw_profile?.raw;
        let userRawBanks = this.profile.user_raw_banks?.raw;
        let userRawBusinesses = this.profile.user_raw_businesses?.raw;
        let userRawEmployment = this.profile.user_raw_employment?.raw;
        let userRawReverseMortgage = this.profile.user_raw_reverse_mortgage?.raw;
        let userRawMyKNP = this.profile.user_raw_my_knp?.raw;

        this.banks = userRawBanks ? userRawBanks : [];
        this.businesses = userRawBusinesses ? userRawBusinesses : [];

        this.myKnp = {
          applied_banks: userRawMyKNP ? userRawMyKNP.applied_banks : [],
        };

        this.employment = {
          education_level: userRawEmployment
            ? userRawEmployment.education_level
            : "",
          type: userRawEmployment ? userRawEmployment.type : "",
          employer_name: userRawEmployment
            ? userRawEmployment.employer_name
            : "",
          employer_type: userRawEmployment
            ? userRawEmployment.employer_type
            : "",
          employer_eco_sector: userRawEmployment
            ? userRawEmployment.employer_eco_sector
            : "",
          specialisation: userRawEmployment
            ? userRawEmployment.specialisation
            : "",
          income_range: userRawEmployment ? userRawEmployment.income_range : "",
          employment_duration: userRawEmployment
            ? userRawEmployment.employment_duration
            : "",
          unemployment_duration: userRawEmployment
            ? userRawEmployment.unemployment_duration
            : "",
        };

        this.reverseMortgage = {
          address: userRawReverseMortgage ? userRawReverseMortgage.address : "",
          type: userRawReverseMortgage ? userRawReverseMortgage.type : "",
          est_value: userRawReverseMortgage
            ? userRawReverseMortgage.est_value
            : "",
          est_outstanding_balance: userRawReverseMortgage
            ? userRawReverseMortgage.est_outstanding_balance
            : "",
          encumbered: userRawReverseMortgage
            ? userRawReverseMortgage.encumbered
            : "",
          has_joinedHolder: userRawReverseMortgage
            ? userRawReverseMortgage.has_joinedHolder
            : "",
        };

        this.isProfileReady = true;
      });
    },
  },
  async mounted() {
    this.init();
  },
});
