
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import moment from "moment";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "update-personal",
  props: ["userProfile", "userBanks"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const addSpouseModal = ref(null);
    const closeModal = () => Modal.getInstance(addSpouseModal.value)?.hide();
    onMounted(() => {
      setCurrentPageBreadcrumbs("Update Personal Information", ["My Profile"]);
    });

    return {
      addSpouseModal,
      closeModal,
    };
  },
  data() {
    const profileDetailsValidator = Yup.object({
      profile: Yup.object({
        name: Yup.string().required().label("Name"),
        nric: Yup.string().required().label("NRIC"),
        email: Yup.string().email().required().label("Email"),
        phone: Yup.number()
          .required()
          .label("Phone No")
          .test(
            "minDigits",
            "Please enter a valid Phone No. eg: 0123456789",
            (number) => String(number).length >= 8
          )
          .typeError("Please enter a valid Phone No. eg: 0123456789"),
        gender: Yup.string().required().label("Gender"),
        birthdate: Yup.string()
          .required()
          .label("Birthdate")
          .typeError("Please select date."),
        user_raw_profile: Yup.object({
          raw: Yup.object({
            race: Yup.string().required().label("Race"),
            employment: Yup.string().required().label("Employment"),
            education: Yup.string().required().label("Education"),
            alt_phone: Yup.number()
              .required()
              .label("Alternative Contact Person No")
              .test(
                "minDigits",
                "Please enter a valid Phone No. eg: 0123456789",
                (number) => String(number).length >= 8
              )
              .typeError("Please enter a valid Phone No. eg: 0123456789"),
            address1: Yup.string().required().label("Address Line 1"),
            address2: Yup.string().required().label("Address Line 2"),
            state: Yup.string().required().label("State"),
            city: Yup.string().required().label("City"),
            postcode: Yup.number()
              .required()
              .label("Postcode")
              .typeError("Please enter a valid Postcode eg: 12345"),
            no_of_dependent: Yup.number()
              .required()
              .label("Number of Dependents"),
            marital_status: Yup.string().required().label("Marital Status"),
          }),
        }),
      }),
    });

    const addSpouseValidator = Yup.object({
      spouse: Yup.object({
        name: Yup.string().required().label("Name"),
        nric: Yup.number()
          .required()
          .label("NRIC")
          .test(
            "exactDigits",
            "Please enter a valid IC No eg: 123456789012",
            (number) => String(number).length === 12
          )
          .typeError("Please enter a valid IC No eg: 123456789012"),
        email: Yup.string().email().required().label("Email"),
        phone: Yup.number()
          .required()
          .label("Phone No")
          .test(
            "minDigits",
            "Please enter a valid Phone No. eg: 0123456789",
            (number) => String(number).length >= 8
          )
          .typeError("Please enter a valid Phone No. eg: 0123456789"),
        employment: Yup.string().required().label("Employment Status"),
      }),
    });

    const changePassword = Yup.object().shape({
      newpassword: Yup.string()
        .min(4)
        .required()
        .label("Password")
        .matches(
          /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          "Password must contain at least one uppercase, one number and one special case character"
        ),
      confirmpassword: Yup.string()
        .min(4)
        .required()
        .oneOf([Yup.ref("newpassword"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const genders = [
      {
        value: "Male",
        label: "Male",
      },
      {
        value: "Female",
        label: "Female",
      },
    ];

    const maritalStatuses = [
      {
        value: "Single",
        label: "Single",
      },
      {
        value: "Married",
        label: "Married",
      },
      {
        value: "Widowed",
        label: "Widowed",
      },
      {
        value: "Divorced",
        label: "Divorced",
      },
    ];

    const employmentStatuses = [
      {
        value: "Employed",
        label: "Employed",
      },
      {
        value: "Unemployed",
        label: "Unemployed",
      },
    ];

    const employments = [
      {
        value: "Government",
        label: "Government",
      },
      {
        value: "Private",
        label: "Private",
      },
      {
        value: "Retiree",
        label: "Retiree",
      },
      {
        value: "Self Employed",
        label: "Self Employed",
      },
      {
        value: "Unemployed",
        label: "Unemployed",
      },
    ];

    const educations = [
      {
        value: "None",
        label: "None",
      },
      {
        value: "School (Primary/Secondary)",
        label: "School (Primary/Secondary)",
      },
      {
        value: "Undergraduate (Certificate/Dimploma/Degree)",
        label: "Undergraduate (Certificate/Dimploma/Degree)",
      },
      {
        value: "Postgraduate/Profesional",
        label: "Postgraduate/Profesional",
      },
    ];

    const cities = [];
    const states = [];
    const races = [];
    const store = useStore();

    const profile = {
      id: null,
      name: "",
      nric: "",
      email: "",
      phone: "",
      gender: "",
      birthdate: "",
      user_raw_profile: {
        id: null,
        user_id: null,
        type: "profile",
        raw: {
          name: "",
          nric: "",
          email: "",
          phone: "",
          gender: "",
          race: "",
          birthdate: "",
          alt_phone: "",
          address1: "",
          address2: "",
          address3: "",
          state: "",
          city: "",
          postcode: "",
          marital_status: "",
          no_of_dependent: 0,
        },
      },
      user_raw_spouses: {
        id: null,
        user_id: null,
        type: "spouse",
        raw: [],
      },
    };

    const spouse = {
      name: "",
      nric: "",
      email: "",
      phone: "",
      employment: "",
    };

    return {
      store,
      profileDetailsValidator,
      addSpouseValidator,
      changePassword,
      genders,
      maritalStatuses,
      profile,
      cities,
      states,
      races,
      employments,
      educations,
      employmentStatuses,
      spouse,
    };
  },
  async mounted() {
    let tempProfile = this.userProfile;
      if (!tempProfile.user_raw_profile) {
        tempProfile.user_raw_profile = this.profile.user_raw_profile;
      }
      if (!tempProfile.user_raw_spouses) {
        tempProfile.user_raw_spouses = this.profile.user_raw_spouses;
      }
      this.profile = tempProfile;
      console.log(this.profile,this.userProfile);
      if (this.profile.gender == null || this.profile.gender == "") {
        this.profile.gender = this.getGender(this.profile.nric);
      }
      if (this.profile.birthdate == null || this.profile.birthdate == "") {
        this.profile.birthdate = this.getBirthdate(this.profile.nric);
      }

      this.store.dispatch(Actions.GET_STATES).then(() => {
        this.states = this.store.getters.getStatesData;
        this.getCities(this.profile.user_raw_profile.raw.state);
      });

      this.store.dispatch(Actions.GET_RACES).then(() => {
        this.races = this.store.getters.getRacesData;
      });
  },
  methods: {
    moment: function () {
      return moment();
    },
    getHumanDate(date) {
      return moment(date).format("DD MMMM YYYY");
    },
    getBirthdate(nric) {
      let birthdate = "";
      if (nric.length == 12) {
        let year = nric.substring(0, 2);
        let month = nric.substring(2, 4);
        let day = nric.substring(4, 6);

        if (parseInt(year) > 40 && parseInt(year) < 99) {
          year = "19" + year;
        } else {
          year = "20" + year;
        }

        birthdate = year + "-" + month + "-" + day;
      }
      return birthdate;
    },
    getGender(nric) {
      let gender = "";
      if (nric.length == 12) {
        let genNo = nric.substring(10, 12);

        if (genNo % 2 == 0) {
          gender = "Female";
        } else {
          gender = "Male";
        }
      }
      return gender;
    },
    getCities(state) {
      this.store
        .dispatch(Actions.GET_CITIES, this.getStateId(state))
        .then(() => {
          this.cities = this.store.getters.getCitiesData;
        });
    },
    getStateId(state_name) {
      for (let index of Object.keys(this.states)) {
        if (state_name == this.states[index].ParameterValue) {
          return this.states[index].id;
        }
      }
    },
    onStateChange() {
      this.getCities(this.profile.user_raw_profile.raw.state);
    },
    updateProfile() {
      if (
        this.profile.user_raw_profile.raw.marital_status == "Married" &&
        this.profile.user_raw_spouses.raw.length == 0
      ) {
        Swal.fire({
          title: "Oops!",
          text: "To proceed, kindly enter details for at least one spouse!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      } else {
        this.profile.user_raw_profile.raw.name = this.profile.name;
        this.profile.user_raw_profile.raw.nric = this.profile.nric;
        this.profile.user_raw_profile.raw.email = this.profile.email;
        this.profile.user_raw_profile.raw.phone = this.profile.phone;
        this.profile.user_raw_profile.raw.gender = this.profile.phone;
        this.profile.user_raw_profile.raw.birthdate = this.profile.birthdate;

        this.store.dispatch(Actions.UPDATE_PROFILE, this.profile).then(() => {
          Swal.fire({
            title: "Congratulation!",
            text: "Your Profile has been Updated!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        });
      }
    },
    onAddSpouse() {
      this.profile.user_raw_spouses.raw.push(this.spouse);
      this.spouse = {
        name: "",
        nric: "",
        email: "",
        phone: "",
        employment: "",
      };
      this.closeModal();
    },
    onRemoveSpouse(index) {
      this.profile.user_raw_spouses.raw.splice(index, 1);
    },
  },
});
